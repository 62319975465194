import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

function Vision({data}) {
    return (
        <div className="tekrevol_vision p-100" id="vision">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="text-center m-auto  order-2 order-md-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'vission_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'mission_title')}/>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto offset-lg-1 order-1 order-md-2">
                        <div className="vision_title">
                            <h2>{checkData(data, 'vission_title')}</h2>
                            <div dangerouslySetInnerHTML={{__html: checkData(data, 'vission_description')}}/>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Vision;